.primary-section {
  background-color: $primary;
  .row {
    color: $white;
    a {
      color: $white;
      &:hover {
        color: $primary;
        background-color: $white;
      }
    }
  }
}
.secondary-section {
  background-color: $secondary;
  .row {
    color: $white;
    a {
      color: $white;
      &:hover {
        color: $secondary;
        background-color: $white;
      }
    }
  }
}
.black-section {
  background-color: $black;
  .row {
    color: $white;
    a {
      color: $white;
      &:hover {
        color: $black;
        background-color: $white;
      }
    }
  }
}
